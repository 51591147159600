import React from 'react'

export default () => {
  return (
    <ul style={{ padding: '20px' }}>
      <li>
        Enclomiphene Prescribing information,{' '}
        <a href="https://drive.google.com/file/u/1/d/1rWaAHpedQnsMgBjecs96Gzk1hMUjQnMB/view">click here</a>
      </li>
      <li>
        Pregnenolone Prescribing information,{' '}
        <a href="https://www.rxlist.com/pregnenolone/supplements.htm">click here</a>
      </li>
    </ul>
  )
}
